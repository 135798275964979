.heading {
  background-color: #0066B2;
  background-image: url("../../assets/patron.svg");
  background-size: contain;
  padding: 32px 40px;
}

.heading__title {
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: #fff;
  line-height: 49px;
  max-width: 1340px;
  margin: 0 auto;
}
