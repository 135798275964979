*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
}

ol {
  padding-left: 20px;
}

a {
  background-color: transparent;
  color: #007bff;
  text-decoration: none;
}
